// function to get 9 random assignments
function getRandomAssignments(array, numElements) {
  // Shuffling the array
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  // Mapping to create objects with specified keys, including index
  const result = array.slice(0, numElements).map((assignment, index) => ({
    assignment: assignment,
    checked: false,
    index: index,
  }));

  return result;
}

export default getRandomAssignments;
