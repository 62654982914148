import ConfettiExplosion from "react-confetti-explosion";
import { useState, useEffect } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import bingoCircle from "../Images/bingoCircle.png";

const GameField = ({ assignment, updateAssignment, index, triggerReset }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(assignment.checked);
  }, [assignment]);

  function handleClick() {
    // updateAssignment(checked, index);
    setChecked(!checked);
    updateAssignment(checked, assignment.index);
  }

  function triggerAnimation(delay) {
    setChecked(true);
    console.log(checked);
    const initialDelay = delay;
    const delayAfterInitial = index * 300;

    const initialTimeoutId = setTimeout(() => {
      const timeoutId = setTimeout(() => {
        setChecked(assignment.checked);
      }, delayAfterInitial);

      return () => clearTimeout(timeoutId);
    }, initialDelay);

    return () => clearTimeout(initialTimeoutId);
  }

  useEffect(() => {
    triggerAnimation(1000);
  }, []);

  return (
    <div className="col-4 square">
      <div className="content  p-2">
        <div className="flip-card">
          <div
            className={
              checked ? "flip-card-inner" : "flip-card-now flip-card-inner"
            }
            onClick={handleClick}
          >
            <div className="flip-card-front align-items-center d-flex">
              <img
                src={bingoCircle}
                alt="circle"
                className="smallBingoCircle"
              />
            </div>
            <div className="flip-card-back d-flex align-items-center justify-content-center">
              <div className="px-2 ">
                <p
                  className={`assignmentText m-0 ${
                    checked === false ? "" : "white-text"
                  }`}
                >
                  {assignment.assignment}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameField;
