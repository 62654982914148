import Game from "./Components/Game";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { useState } from "react";

function App() {
  const [triggerReset, setTriggerReset] = useState(false);

  function reset() {
    setTriggerReset(true);

    const timeoutId = setTimeout(() => {
      setTriggerReset(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }

  return (
    <div className="App">
      <Header />
      <Game triggerReset={triggerReset} />
      <Footer reset={reset} />
    </div>
  );
}

export default App;
