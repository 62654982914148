import bingoHeaderBox from "../Images/bingoHeaderBox.png";

const Header = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img
              src={bingoHeaderBox}
              alt="headerbox"
              className="bingoHeaderBox hithere"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
