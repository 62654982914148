const assignments = [
  "Achternaam met meer dan 10 letters heeft",
  "Al eens verhuisd is",
  "Altijd wint met FIFA",
  "Die al oom of tante is",
  "Die een huisnummer boven de 100 heeft",
  "Die een instrument speelt",
  "Die een strikje om heeft",
  "Die gedoopt is",
  "Die in een Clan zit",
  "Die linkshandig is",
  "Die op een boerderij woont",
  "Die weet wat hij later wil worden",
  "Die wel eens iets gebroken heeft",
  "Een crompouce heeft gegeten",
  "Een hond heeft",
  "Een kat heeft",
  "Een pizza met ananas zou eten",
  "Een van de ouders een eigen bedrijf heeft",
  "Een van de ouders in de zorg werkt",
  "Houdt van fotografie",
  "Iemand die bij WAY camp was",
  "Iemand die gitaar speelt",
  "Iemand die kattenfilmpjes kijkt",
  "Iemand die patat zegt ipv friet",
  "Iemand met dezelfde favoriete Youtuber",
  "Iemand met dezelfde geboortemaand",
  "Iemand met dezelfde muzieksmaak",
  "iemand met dezelfde schoenmaat",
  "iemand met dezelfde voor- of achternaam",
  "Iets roods aan heeft",
  "In het buitenland heeft gewoond",
  "Jarig is in december",
  "Jarig is in november",
  "Leer een nieuw danspasje van iemand",
  "Liever appelflappen eet dan oliebollen",
  "Nog niet in een clan zit",
  "Post een foto van vanavond in je stories",
  "Telnr eindigt op 5",
  "Thuis de jongste is",
  "Thuis de oudste is",
  "Twee broers heeft",
  "Vanavond met een bus is gekomen",
  "Wel eens op TV is geweest",
  "Zit op voetbal",
];

export default assignments;
