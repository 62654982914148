import mozaiekLogo from "../Images/mozaiekLogo.png";

const Footer = ({ reset }) => {
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-center">
        <img src={mozaiekLogo} className="mozaiekLogo" alt="mozaiek logo" />
      </div>
      <div className="mt-3 d-flex justify-content-center mb-5">
        <button className="btn resetBtn" onClick={reset}>
          Reset dit spel
        </button>
      </div>
    </div>
  );
};

export default Footer;
