import GameField from "./GameField";
import Assignments from "../Assignments";
import { useState, useEffect } from "react";
import getRandomAssignments from "../Utils/getRandomAssignments";
import { useWindowSize } from "@uidotdev/usehooks";
import Confetti from "react-confetti";

const Game = ({ triggerReset }) => {
  const { width, height } = useWindowSize();
  const [assignments, setAssignments] = useState();
  const [wonGame, setWonGame] = useState(false);

  useEffect(() => {
    let storedData = localStorage.getItem("savedGame");

    if (storedData === undefined) {
      localStorage.removeItem("savedGame");
    }

    if (storedData) {
      console.log(storedData);
      setAssignments(JSON.parse(storedData));
    } else {
      const results = getRandomAssignments(Assignments, 9);
      setAssignments(results);
    }
  }, []);

  //update checked status
  function updateAssignment(state, index) {
    const updatedAssignments = assignments.map((assignment) => {
      if (assignment.index === index) {
        return { ...assignment, checked: !assignment.checked };
      } else {
        return assignment;
      }
    });
    setAssignments(updatedAssignments);
  }

  function handleReset() {
    localStorage.removeItem("savedGame");
    const results = getRandomAssignments(Assignments, 9);
    setAssignments(results);
  }

  useEffect(() => {
    if (triggerReset === true) {
      handleReset();
    }
  }, [triggerReset]);

  //check is game is won
  useEffect(() => {
    if (assignments && assignments.length > 0) {
      const allChecked = assignments.every((item) => item.checked);
      setWonGame(allChecked);
    }
  }, [assignments]);

  //save last game
  useEffect(() => {
    localStorage.setItem("savedGame", JSON.stringify(assignments));
  }, [assignments]);

  return (
    <div className="container">
      <div className="row g-1 ">
        {assignments &&
          assignments.map((assignment, index) => (
            <GameField
              key={index}
              assignment={assignment}
              index={index}
              updateAssignment={updateAssignment}
              triggerReset={triggerReset}
            />
          ))}
        {wonGame && <Confetti width={width} height={height} />}
      </div>
    </div>
  );
};

export default Game;
